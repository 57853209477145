import WebLayout, { Props as WebLayoutProps } from '@/layouts/web/components/WebLayout';
import { createLogger } from '@/modules/core/logging/logger';
import React from 'react';

const fileLabel = 'layouts/public/components/PublicLayout';
const logger = createLogger({
  fileLabel,
});

type Props = Omit<WebLayoutProps, 'layoutName'>;

/**
 * Overrides the CoreLayout to adapt it to the Public layout.
 *
 * Hides nav, footer and preview banner and applies some custom CSS for demonstration purpose.
 */
const PublicLayout: React.FunctionComponent<Props> = (props): JSX.Element => {
  // console.log('publiclayoutprops', props);
  return (
    <WebLayout
      layoutName={'public-layout'}
      hideFooter={true}
      hideNav={true}
      hidePreviewBanner={true}
      {...props}
    />
  );
};

export default PublicLayout;
